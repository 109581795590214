import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Nav from './components/nav';
import Fot from './components/footer';
import whatsapp from './assets/whatsapp.webp';
import email from './assets/email.webp';
import { Link } from 'react-router-dom';
import './style.css';

export default function Contactus() {
    const { t } = useTranslation();
    const [lightModeScreen, setLightModeScreen] = useState(true);

    useEffect(() => {
        lightModeScreenManager();
        const intervalId = setInterval(lightModeScreenManager, 100);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    function lightModeScreenManager() {
        let savedValueForMode = localStorage.getItem('Mode');
        if (savedValueForMode == null) {
            setLightModeScreen(true);
            localStorage.setItem('Mode', true);
        } else {
            setLightModeScreen(savedValueForMode === 'true');
        }
    }

    return (
        <>
            <Nav />

            <div className="container" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                <div className="contact-cards" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <div className="contact-card" style={{ backgroundColor: lightModeScreen ? 'white' : '#3b3b3b' }}>
                    <img src={whatsapp} loading="lazy" alt="whatsapp image" />
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("contact1text")}</p>
                        <button onClick={()=> window.open('https://api.whatsapp.com/send?phone=971549957801', '_blank')}>{t("contact1button")}</button>
                    </div>

                    <div className="contact-card" style={{ backgroundColor: lightModeScreen ? 'white' : '#3b3b3b' }}>
                    <img src={email} loading="lazy" alt="email image" />
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("contact2text")}</p>
                        <button onClick={()=> window.open('mailto:reception@ridc.ae', '_blank')}>{t("contact2button")}</button>
                    </div>
                </div>
            </div>

            <hr></hr>
            <div class="map-container" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
          <div class="map-info" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
            <h1>Resonance Imaging & Diagnostic Center</h1>
            <p style={{ color: lightModeScreen ? 'black' : 'white' }}>Villa 1204 Al Wasl Road, Umm Suqeim 2<br />
              Dubai, United Arab Emirates.</p>
            <p><Link to="tel:+97142295550" style={{ color: lightModeScreen ? 'black' : 'white' }}>+971 4 2295550</Link> | <Link to="tel:+971549957801" style={{ color: lightModeScreen ? 'black' : 'white' }}>+971 549957801</Link></p>
            <p style={{ color: lightModeScreen ? 'black' : 'white' }}>For You, We Never Stop.</p>
            <h2>{t("homemapopening")}</h2>
            <ul>
              <li style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homemaptext1")}</li>
              <li style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("homemaptext2")}</li>
            </ul>
          </div>
          <div class="map-embed">
            <iframe id="map-frame" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7223.503821664324!2d55.207168!3d25.144077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69960d2d32d7%3A0x899f8432ad0a8d8d!2sResonance%20Imaging%20and%20Diagnostic%20Center!5e0!3m2!1sen!2sae!4v1711967764968!5m2!1sen!2sae" frameborder="0" allowfullscreen aria-hidden="false" tabindex="0"></iframe>
          </div>
        </div>

            <Fot />
        </>
    );
}
