import React, { useState, useEffect } from 'react';
import Nav from './components/nav';
import { useNavigate } from 'react-router-dom';
import clientimag from './client/clintawe.jpg';
import soionawd from './client/soionawd.jpg';
import { Link } from 'react-router-dom';
export default function Clients() {

    function deleteTokenCookie() {
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = "/login";
    }

    return (
        <div className='admin-whole-page'>
            <Nav />
            <div className="card-container-admins">
                <Link to="/clientsservices">
                    <div className="card-admins">
                        <img src={clientimag} alt="Photo 1" />
                        <h3>client services</h3>
                    </div>
                </Link>
            </div>
            <div className='login-area'>
                <button className='logout-button' onClick={()=> deleteTokenCookie()}>LogOut</button>
                
             </div>

        </div>
    );
}
