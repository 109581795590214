import Nav from './components/nav'
import Fot from './components/footer';
import enjo from './assets/imaginmri.jpg';
import mriimg from './assets/mRI.webp';
import ct from './assets/CT.webp';
import degm from './assets/Digital-Mammography.webp';
import degx from './assets/Digital-Xray.webp';
import degp from './assets/dental-imaging.webp';
import ult from './assets/ULTRASOUND-RIDC.webp';
import ultrasound from './ultrasound';
import mri from './mri';
import enjoMri from './enjomriexp';
import Digitalmomo from './digitalmomo';
import DigitalXray from './digitalxray';
import img1 from './assets/dental-circle-ridc-01-200x200.webp';
import img2 from './assets/services-body-abdominal-200x200.webp';
import img3 from './assets/services-body-brain-spine-200x200.webp';
import img4 from './assets/services-body-chest-200x200.webp';
import img5 from './assets/services-body-mammography-200x200.webp';
import img6 from './assets/services-body-musculoskeletal-200x200.webp';
import img7 from './assets/services-body-prostate-1-200x200.webp';
import img8 from './assets/services-body-prostate-200x200.webp';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
export default function Services() {
    const {t} = useTranslation();

    const [data, setData] = useState(1);
    const [servicesStyle, setServicesstyle] = useState('ser-btn');
    const [bodyStyle, setBodystyle] = useState('');
    const [lightModeScreen, setLightModeScreen] = useState(true);
    const refCardContainer = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const cards = entry.target.querySelectorAll('.card-main');
                    cards.forEach((card) => {
                        card.classList.add('animate-visible');
                    });
                }
            });
        }, {
            threshold: 0.5 // Trigger animation when 50% of the element is visible
        });

        // Start observing when components are mounted
        observer.observe(refCardContainer.current);

        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        lightModeScreenManager();
        const intervalId = setInterval(lightModeScreenManager, 100);
        return () => {
          clearInterval(intervalId);
        };
    }, []);

    function lightModeScreenManager() {
        let savedValueForMode = localStorage.getItem('Mode');
        if (savedValueForMode == null) {
          setLightModeScreen(true);
          localStorage.setItem('Mode', true);
        } else {
          setLightModeScreen(savedValueForMode === 'true');
        }
      }
    

    function handleServicesClick() {
        setData(1);
        setServicesstyle('ser-btn');
        setBodystyle('');
    }

    function handleBodyClick() {
        setData(2);
        setServicesstyle('');
        setBodystyle('ser-btn');
    }
    function dataLoad(data){
        if (data === 1){
            return(
            <div ref={refCardContainer} className="all-cards-main">
            <Link to={"/enjoymriexperience"} className="card-link">
                <div className="card-main">
                    <img src={enjo} alt="Image" />
                    <div className="card-content-main">
                        <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesenjoyablemrimaintext")}</h2>
                        
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesenjoyablemrimaindesc")} </p>
                        
                        <span style={{ color: lightModeScreen ? 'black' : 'white' }}> {t("servicesenjoyablemrimainclick")} </span> 
                    </div>
                </div>
            </Link>
            <Link to={"/mri"} className="card-link">
                <div className="card-main">
                    <img src={mriimg} alt="Image" />
                    <div className="card-content-main">
                        <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesmrimaintext")}</h2>
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesmrimaindesc")}</p>
                        <span style={{ color: lightModeScreen ? 'black' : 'white' }}> {t("servicesmrimainclick")} </span> 
                    </div>
                </div>
            </Link>
            <Link to={"/ctscan"} className="card-link">
                <div className="card-main">
                    <img src={ct} alt="Image" />
                    <div className="card-content-main">
                        <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesctscanmaintext")}</h2>
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesctscanmaindesc")} </p>
                        <span style={{ color: lightModeScreen ? 'black' : 'white' }}> {t("servicesctscanmainclick")} </span> 
                    </div>
                </div>
            </Link>
            <Link to={"/digitalmammogram"} className="card-link">
                <div className="card-main">
                    <img src={degm} alt="Image" />
                    <div className="card-content-main">
                        <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesdigitalmammogrammaintext")}</h2>
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesdigitalmammogrammaindesc")}</p>
                        <span style={{ color: lightModeScreen ? 'black' : 'white' }}> {t("servicesdigitalmammogrammainclick")} </span> 
                    </div>
                </div>
            </Link>
            <Link to={"/digitalxray"} className="card-link">
                <div className="card-main">
                    <img src={degx} alt="Image" />
                    <div className="card-content-main">
                        <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesdigitalxraymaintext")}</h2>
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesdigitalxraymaindesc")}</p>
                        <span style={{ color: lightModeScreen ? 'black' : 'white' }}> {t("servicesdigitalxraymainclick")} </span> 
                    </div>
                </div>
            </Link>
            <Link to={"/dentalimagining"} className="card-link">
                <div className="card-main">
                    <img src={degp} alt="Image" />
                    <div className="card-content-main">
                        <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesdentalimagingmaintext")}</h2>
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesdentalimagingmaindesc")}</p>
                        <span style={{ color: lightModeScreen ? 'black' : 'white' }}> {t("servicesdentalimagingmainclick")} </span> 
                    </div>
                </div>
            </Link>
            <Link to={"/ultrasound"} className="card-link">
                <div className="card-main">
                    <img src={ult} alt="Image" />
                    <div className="card-content-main">
                        <h2 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesultrasoundmaintext")}</h2>
                        <p style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicesultrasoundmaindesc")}</p>
                        <span style={{ color: lightModeScreen ? 'black' : 'white' }}> {t("servicesultrasoundmainclick")} </span> 

                    </div>
                </div>
            </Link>

        </div>
            );
        }
        else {
            return(
                
            <div ref={refCardContainer} className='body-things-container'>
                <Link to={"/brainhead"} className='link-area-of-body'>
                <div className='body-things'>
                    <div className='body-things-left'>
                        <img src={img3}/>
                    </div>
                    <div className='body-things-right'>
                       
                            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicebrainheadmain")}</h1>
                     
                        
                    </div>
                </div>
                </Link>
                <Link to={"/chestimaging"} className='link-area-of-body'>
                <div className='body-things'>
                    <div className='body-things-left'>
                        <img src={img4}/>
                    </div>
                    <div className='body-things-right'>
                        
                            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicechestimaginigmain")}</h1>
                        
                        
                    </div>
                </div>
                </Link>
                <Link to={"/breastimaging"} className='link-area-of-body'>
                <div className='body-things'>
                    <div className='body-things-left'>
                        <img src={img5}/>
                    </div>
                    <div className='body-things-right'>
                        
                            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicebreastimagingmain")}</h1>
                      
                        
                    </div>
                </div>
                </Link>
                <Link to={"/abdomenimaging" }className='link-area-of-body'>
                <div className='body-things'>
                    <div className='body-things-left'>
                        <img src={img2}/>
                    </div>
                    <div className='body-things-right'>
                       
                            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("serviceabdomenimagingmain")}</h1>
                       
                        
                    </div>
                </div>
                </Link>
                <Link to={"/dentalimaging"} className='link-area-of-body'>
                <div className='body-things'>
                    <div className='body-things-left'>
                        <img src={img1}/>
                    </div>
                    <div className='body-things-right'>
                       
                            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicedentalimagingmain")}</h1>
                       
                       
                    </div>
                </div>
                </Link>
                <Link to={"/prostateimaging"} className='link-area-of-body'>
                <div className='body-things'>
                    <div className='body-things-left'>
                        <img src={img8}/>
                    </div>
                    <div className='body-things-right'>
                      
                            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("serviceprostateimagingmain")}</h1>
                      
                        
                    </div>
                </div>
                </Link>
                <Link to={"/maskuloskelettalimaging" }className='link-area-of-body'>
                <div className='body-things'>
                    <div className='body-things-left'>
                        <img src={img6}/>
                    </div>
                    <div className='body-things-right'>
                       
                            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicemuskulomain")}</h1>
                      
                        
                    </div>
                </div>
                </Link>
                <Link to={"./vascularimaging"} className='link-area-of-body'>
                <div className='body-things'>
                    <div className='body-things-left'>
                        <img src={img8}/>
                    </div>
                    <div className='body-things-right'>
                        
                            <h1 style={{ color: lightModeScreen ? 'black' : 'white' }}>{t("servicevascularmain")}</h1>
                       
                       
                    </div>
                </div>
                </Link>
            </div>
            
            );
        }
        
    }
    return (
        <div className="services-main-page-container" style={{ backgroundColor: lightModeScreen ? '#fff' : '#1E1E1E' }}>
            <Nav />
            <div className="services-header-area" style={{ backgroundColor: lightModeScreen ? '#fff' : '#1E1E1E' }}>
                <div className="services-header">
                    <h1 style={{ color: lightModeScreen ? 'black' : '#fff' }}>  {t("servicesmaintext")}   </h1>
                </div>
                <div className="services-parag">
                    <p style={{ color: lightModeScreen ? '#5d5d5d' : '#e6e6e6' }}>  {t("servicesmaindesc")}
                    </p>
                </div>
            </div>

            <div className="services-button-area">
                <button className={servicesStyle} onClick={handleServicesClick} style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E', color: lightModeScreen ? '#1E1E1E' : 'white' }}>{t("servicesservicetext")}</button>
                <button className={bodyStyle} onClick={handleBodyClick} style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' , color: lightModeScreen ? '#1E1E1E' : 'white'}}>{t("servicesareaofbodytext")}</button>
            </div>
            {dataLoad(data)}



            <Fot />
        </div>
    );
};