import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function LoginPage() {

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const checkUserRole = async () => {
        try {
            const token = getCookie('token');
            if (!token) {
                return;
            }

            const response = await fetch('https://ridc.ae/getuserrole', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message);
                if (data.rank === "admin") {
                    window.location.href = "/admin";
                } else if (data.rank === "client") {
                    window.location.href = "/clients";
                }else if (data.rank === "doctor") {
                    window.location.href = "/doctors";
                } else {
                    return;
                }
            } else {
                if (response.status === 401) {
                    console.log("Unauthorized: User not logged in");
                } else {
                    throw new Error('Network response was not ok');
                }
            }
        } catch (error) {
            console.error('Error checking user role:', error);
        }
    };

    checkUserRole();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Submitting form with username:", username);
        console.log("Submitting form with password:", password);
        if (checkChars(username, password) == false) {
            try {
                const response = await fetch("https://ridc.ae/loginmanager", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ username: username, password: password })
                });

                console.log("Response status:", response.status);

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const data = await response.json(); // Parse JSON response
                console.log("Response data:", data);
                clearInputs();

                if (data.token) {
                    // Save the token in cookies
                    document.cookie = `token=${data.token}`;
                    checkUserRole();
                    clearInputs();
                } else {
                    const errorMessage = document.getElementById("error-message");
                    errorMessage.style.display = "block";
                    clearInputs();
                }
            } catch (error) {
                console.error("Fetch error:", error);
                // Display an alert or set a state to show a message to the user
                if (error && error.status === 401) {
                    setErrorMessage("Incorrect username or password");
                } else {
                    console.log("An error occurred");
                    // Set state to display a generic error message to the user
                }
                clearInputs();
            }
        } else {
            setErrorMessage("Please remove any special characters you are using!");
            const errorMessage = document.getElementById("error-message");
            errorMessage.style.display = "block";
        }
    };

    function clearInputs() {
        setUsername("");
        setPassword("");
    }

    function containsSpecialCharacters(str) {
        const pattern = /[<>&'"]/;
        return pattern.test(str);
    }

    function checkChars(username, password) {
        const strings = [username, password];
        for (const str of strings) {
            if (containsSpecialCharacters(str)) {
                return true;
            }
        }

        return false;
    }

    return (
        <div className="fullloginpage">
            <div className="loginpage">
                <h1>Sign in</h1>
                <form onSubmit={handleSubmit} className="formlogin">
                    <label htmlFor="username" className="labellogin">Username:</label>
                    <input className="inputlogin" type="text" id="username" value={username} onChange={handleUsernameChange} placeholder="Enter your username:" />
                    <label htmlFor="password" className="labellogin">Password:</label>
                    <input className="inputlogin" type="password" id="password" value={password} onChange={handlePasswordChange} placeholder="Enter your password:" />
                    <div className="signup-container">
                        <h5 id="error-message" style={{ display: 'none' }}>Incorrect username or password or using special character!</h5>
                        <Link to="" className="signup-link">Signup</Link>
                    </div>
                    <button className="buttonlogin" type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
}
