import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import loggo from '../assets/logo2.webp';
import englishlogo from '../assets/languages.webp';
import englishlogodark from '../assets/languagesdark.webp';
import germanf from '../assets/flags/de.webp';
import frenchf from '../assets/flags/france.webp';
import indiaf from '../assets/flags/india.webp';
import irf from '../assets/flags/ir.webp';
import phf from '../assets/flags/ph.webp';
import russia1 from '../assets/flags/russia.webp';
import uaef from '../assets/flags/uae.webp';
import ch1 from '../assets/flags/china.webp';
import pk from '../assets/flags/pk.webp';
import ukflag from '../assets/flags/UK.webp';
import lightmode from '../assets/lightmode.webp';
import darkmodeDARK from '../assets/darkmodeDARK.webp';
import aboutusDARK from '../assets/aboutusDARK.webp';
import patientDark from '../assets/patientDark.webp';
import servicesDark from '../assets/servicesDark.webp';
import homeDARK from '../assets/homeDARK.webp';
import DARKLOGO from '../assets/DARKLOGO.webp';
import homeimagelink from './Assets/homepagelink.png';
import aboutimagelink from './Assets/aboutuspagelink.png';
import patientimagelink from './Assets/patientportallink.png';
import servicesimagelink from './Assets/servicespagelink.png';
import team from '../assets/handshake-solid-_1_.webp';
import teamDark from '../assets/handshake-solid.webp';
import gallery from '../assets/image-solid-_1_.webp';
import galleryDark from '../assets/image-solid.webp';
import contactus from '../assets/phone-solid-_1_.webp';
import contactusDark from '../assets/phone-solid.webp';
import { Link } from 'react-router-dom';
import whatsappfloating from '../assets/whatsappfloating.webp';
import bookfloating from '../assets/pen-to-square-solid.webp';

export default function NavBar() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [languageMenuDisplayer, setLanguageMenuDisplayer] = useState(false);
  const [language, setLanguage] = useState("en");
  const [lightModeScreen, setLightModeScreen] = useState(true);

  useEffect(() => {
    if (languageMenuDisplayer) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [languageMenuDisplayer]);

  useEffect(() => {
    languageManager();
    lightModeScreenManager();
  }, []);

  function handleLanguageMenuOpener() {
    setLanguageMenuDisplayer(!languageMenuDisplayer);
  }

  function setLanguageType(lang) {
    setLanguage(lang);
    localStorage.setItem('Language', lang);
  }

  function languageManager() {
    let savedValue = localStorage.getItem('Language');
    if (savedValue) {
      setLanguage(savedValue);
    } else {
      setLanguage("en");
      localStorage.setItem('Language', 'en');
    }
  }

  function lightModeScreenManager() {
    let savedValueForMode = localStorage.getItem('Mode');
    if (savedValueForMode === null) {
      setLightModeScreen(true);
      localStorage.setItem('Mode', 'true');
    } else {
      setLightModeScreen(savedValueForMode === 'true');
    }
  }

  function setLocalStorageForScreenMode() {
    let x = !lightModeScreen;
    setLightModeScreen(x);
    localStorage.setItem('Mode', x);
  }

  return (
    <>
      <nav className="navbar" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
        <div className="navbar-logo">
          <img src={lightModeScreen ? loggo : DARKLOGO} className="nav-bar-logo" alt="Company Logo" />
        </div>
        <button className="menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </button>
        {isMenuOpen && (
          <div className="menu-overlay" onClick={() => setIsMenuOpen(false)} >
            <div className="menu-content" onClick={(e) => e.stopPropagation()} style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
              <ul className="nav-links-menu">
                <li>
                  <Link to="/" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? homeimagelink : homeDARK} alt="Home" className="link-image" />
                    {t("nav1")}
                  </Link>
                </li>
                <li>
                  <Link to="/aboutus" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? patientimagelink : patientDark} alt="About Us" className="link-image" />
                    {t("nav6")} ▾
                  </Link>
                </li>
                <li className="dropdown">
                  <Link to="/services" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? aboutimagelink : aboutusDARK} alt="Services" className="link-image" />
                    {t("nav2")} ▾
                  </Link>
                  <ul className="dropdown-content" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                  <li><Link to="/mri" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist1")}</Link></li>
                    <li><Link to="/ctscan" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist2")}</Link></li>
                    <li><Link to="/ultrasound" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist3")}</Link></li>
                    <li><Link to="/digitalxray" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist4")}</Link></li>
                    <li><Link to="/digitalmammogram" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist5")}</Link></li>
                    <li><Link to="/dentalimagining" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist6")}</Link></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link to="/ourteam" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? teamDark : team} alt="Team" className="link-image" />
                    {t("ourteaminnav")}
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? galleryDark : gallery} alt="Gallery" className="link-image" />
                    {t("galleryinnav")}
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? contactusDark : contactus} alt="Contact us" className="link-image" />
                    {t("contactusinnav")}
                  </Link>
                </li>
                <li>
                  <Link to="/clients" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? servicesimagelink : servicesDark} alt="Patient Portal" className="link-image" />
                    {t("nav8")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )}
         <ul className="nav-links">
                <li>
                  <Link to="/" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? homeimagelink : homeDARK} alt="Home" className="link-image" />
                    {t("nav1")}
                  </Link>
                </li>
                <li>
                  <Link to="/aboutus" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? patientimagelink : patientDark} alt="About Us" className="link-image" />
                    {t("nav6")}
                  </Link>
                </li>
                <li className="dropdown">
                  <Link to="/services" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? aboutimagelink : aboutusDARK} alt="Services" className="link-image" />
                    {t("nav2")} ▾
                  </Link>
                  <ul className="dropdown-content" style={{ backgroundColor: lightModeScreen ? 'white' : '#1E1E1E' }}>
                    <li><Link to="/mri" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist1")}</Link></li>
                    <li><Link to="/ctscan" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist2")}</Link></li>
                    <li><Link to="/ultrasound" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist3")}</Link></li>
                    <li><Link to="/digitalxray" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist4")}</Link></li>
                    <li><Link to="/digitalmammogram" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist5")}</Link></li>
                    <li><Link to="/dentalimagining" style={{ color: lightModeScreen ? '#333' : 'white' }}>{t("navserviceslist6")}</Link></li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link to="/ourteam" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? teamDark : team} alt="Team" className="link-image" />
                    {t("ourteaminnav")}
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? galleryDark : gallery} alt="Gallery" className="link-image" />
                    {t("galleryinnav")}
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? contactusDark : contactus} alt="Contact us" className="link-image"/>
                    {t("contactusinnav")}
                  </Link>
                </li>
                <li>
                  <Link to="/clients" className="nav-link" style={{ color: lightModeScreen ? '#333' : 'white' }}>
                    <img src={lightModeScreen ? servicesimagelink : servicesDark} alt="Patient Portal" className="link-image" />
                    {t("nav8")}
                  </Link>
                </li>
              </ul>
        <div className="nav-buttons">
          <Link to="" onClick={(e) => { e.preventDefault(); handleLanguageMenuOpener(); }}>
            <img src={lightModeScreen ? englishlogo : englishlogodark} alt="Earth Icon" width="85px" height="85px" className='lang-logo-image' />
          </Link>
          <Link to="" onClick={() => { setLightModeScreen(!lightModeScreen); setLocalStorageForScreenMode(); }}>
            <img src={lightModeScreen ? lightmode : darkmodeDARK} alt="Light and dark mode icon" width="45px" height="45px" className='lang-logo-image' />
          </Link>
          <Link to="/appointments" className='appointment-button'>{t("nav9")}</Link>

          <div className="floating-icons">
  <Link to="/appointments"><img src={bookfloating} alt="book" /></Link>
  <Link to="https://api.whatsapp.com/send?phone=971549957801"><img src={whatsappfloating} alt="whatsapp" /></Link>
</div>
        </div>
      </nav>

      {languageMenuDisplayer && (
        <div className="backgroundlanguesmenu">
          <div className="languagesmenu">
            <h1>{t("nav10")}</h1>
            <div className='lang-row'>
              <div className="language-item">
                <Link to="" onClick={() => setLanguageType("en")}>
                  <div className='language-images-holder'>
                    <img src={ukflag} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">En</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("ar")}>
                  <div className='language-images-holder'>
                    <img src={uaef} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">Ar</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("de")}>
                  <div className='language-images-holder'>
                    <img src={germanf} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">De</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("fr")}>
                  <div className='language-images-holder'>
                    <img src={frenchf} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">Fr</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("in")}>
                  <div className='language-images-holder'>
                    <img src={indiaf} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">In</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("ir")}>
                  <div className='language-images-holder'>
                    <img src={irf} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">Ir</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("pk")}>
                  <div className='language-images-holder'>
                    <img src={pk} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">Pk</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("ph")}>
                  <div className='language-images-holder'>
                    <img src={phf} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">Ph</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("ru")}>
                  <div className='language-images-holder'>
                    <img src={russia1} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">Ru</span>
                  </div>
                </Link>
                <Link to="" onClick={() => setLanguageType("ch")}>
                  <div className='language-images-holder'>
                    <img src={ch1} className="flag-image" />
                  </div>
                  <div className='language-txt-holder'>
                    <span className="language-name">Ch</span>
                  </div>
                </Link>
              </div>
              <div className='close-button-holder'>
                <button className='close-button-lang' onClick={handleLanguageMenuOpener}> {t("nav11")} </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
