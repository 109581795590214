
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faPinterestP, faGoogleMaps, faWhatsapp, faOutlook } from '@fortawesome/free-brands-svg-icons';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function Footer(){
  const [lightModeScreen, setLightModeScreen] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    lightModeScreenManager();
    const intervalId = setInterval(lightModeScreenManager, 100);
    return () => {
      clearInterval(intervalId);
    };
}, []);

function lightModeScreenManager() {
  let savedValueForMode = localStorage.getItem('Mode');
  if (savedValueForMode == null) {
    setLightModeScreen(true);
    localStorage.setItem('Mode', true);
  } else {
    setLightModeScreen(savedValueForMode === 'true');
  }
}
    return(
         <>
        <div className="footer" style={{ backgroundColor: lightModeScreen ? '#00539C' : '#1E1E1E' }}>
      <div className="footer-section">
        <p>Umm Suqeim 2</p>
        <p>T: +971 4 2295550</p>
        <p>M: +971 549957801</p>
        <p>E: Reception@ridc.ae</p>
        <p>{t("footer1")}</p>
        <p>{t("footer2")}</p>
        <p>{t("footer3")}</p>
        <p>{t("footer4")}</p>
        
      </div>
      <div className="footer-section">
        <h3>{t("footer5")}</h3>
        <Link to="/enjoymriexperience">{t("footer6")}</Link>
        <Link to="/mri">{t("footer7")}</Link>
        <Link to="/ctscan">{t("footer8")}</Link>
        <Link to="/digitalmammogram">{t("footer9")}</Link>
        <Link to="/ultrasound">{t("footer10")}</Link>
        <Link to="/digitalxray">{t("footer11")}</Link>
        <Link to="/dentalimagining">{t("footer12")}</Link>
      </div>
      <div className="footer-section-middle">
        <div className='footer-sec-head'>
           <h3>{t("footer13")}</h3>
           </div>
       
        <ul className="social-icons">
        <li><Link to="https://www.facebook.com/ResonanceImagingAndDiagnosticCenter" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></Link></li>
        <li><Link to="https://www.instagram.com/resonance_idc/"  target="_blank"><FontAwesomeIcon icon={faInstagram} /></Link></li>
        <li><Link to="https://www.linkedin.com/company/resonance-idc/" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></Link></li>
        <li><Link to="mailto:info@ridc.ae" target="_blank"><FontAwesomeIcon icon={faEnvelope} /></Link></li>
        <li><Link to=" https://www.google.com/maps?ll=25.143912,55.206998&z=16&t=m&hl=en&gl=AE&mapclient=embed&cid=9916790257679502733" target="_blank"><FontAwesomeIcon icon={faMapMarker} /></Link></li>
        <li><Link to="https://twitter.com/resonanceidc" target="_blank"><FontAwesomeIcon icon={faTwitter} /></Link></li>
        <li><Link to="https://pin.it/zXeo0a9" target="_blank"><FontAwesomeIcon icon={faPinterestP} /></Link></li>
        <li><Link to="https://api.whatsapp.com/send?phone=971549957801" target="_blank"><FontAwesomeIcon icon={faWhatsapp} /></Link></li>
        </ul>
      </div>
      <div className="footer-section copyright">
        <p>{t("footer14")}</p>
      </div>
    </div>
    </>
    )
   
};